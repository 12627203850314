import axios from 'axios'
import { Message } from 'view-design'
import { debounce } from '@/utils'
import api from '@/api'
import store from '../store'

axios.defaults.timeout = 30 * 1000

// 环境的切换
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://crs.huyaxxkj.cn:8080'
}

// 请求拦截
axios.interceptors.request.use(
  config => {
    // const token = localStorage.getItem('token') || ''
    const token = store.state.UserToken
    token && (config.headers.Authorization = 'Bearer ' + token)
    // tianjia
    const orgId = store.state.orgId
    try {
      orgId && !config.data.orgId && (config.data.orgId = orgId)
    } catch (error) {
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error && error.response) {
      let { status, data } = error.response
      if (status == 400) Message.error('请求错误')
      if (status == 401) {
        debounce(function() {
          Message.error('用户信息失效,请重新登录')
        }, 500)
        api.logout()
      }
      if (status == 403) Message.error('拒绝访问')
      if (status == 404) Message.error('404：资源不存在')
      if (status == 405) Message.error('请求无效')
      if (status == 408) Message.error('请求超时')
      if (status == 409) Message.error('409：资源冲突')
      if (status == 500) Message.error('500：服务器出错')
      if (status == 501) Message.error('网络未实现')
      if (status == 502) Message.error('502：网络错误')
      if (status == 503) Message.error('服务不可用')
    } else {
      let msg = error.message
      if (msg === `timeout of ${axios.defaults.timeout}ms exceeded`) {
        msg('请求超时！')
      } else {
        console.error('前端捕获未知错误：', msg)
      }
    }
    return Promise.reject(error.response)
  }
)

/** 二次封装 */
let methods = ['get', 'delete', 'head', 'options', 'post', 'put', 'patch']
let paramsInConfig = ['get', 'delete', 'head', 'options']

methods.forEach(method => {
  let fn = axios[method]
  axios[method] = (url, params = {}, config = {}) => {
    if (config.loading) {
    }

    let promise = null
    if (paramsInConfig.includes(method)) {
      config = Object.assign({}, { params }, config)
      promise = fn.call(axios, url, config)
    } else {
      promise = fn.call(axios, url, params, config)
    }

    return new Promise((resolve, reject) => {
      promise
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
})

export default axios
